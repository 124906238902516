/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
.observerStatusRow {
    margin-bottom: 5px;
}

.measureUnits {
    margin-left: 10px;
}
