/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
 .whiteText {
    color: white;
}

.redText {
    color: red;
}

.blueText {
    color: rgb(0, 238, 255);
}

.greenText {
    color: rgb(120, 194, 0);
}

.orangeText {
    color: orange;
}

.logTitle {
    color: white;
    align-items: center;
    font-size: calc(20px);
    font-weight: bold;
    margin: auto;
}
