/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
.neoccInputBox {
    width: 150px;
    margin: 0.5rem 0 1rem 25px;
}

.neoccText {
    margin-top: 0.6rem;
}

.neoccDropdownList {
    height: 400;
}

.btn {
    background-color: DodgerBlue;
    border: none;
    color: white;
    padding: 7px 20px;
    cursor: pointer;
    font-size: 18px;
    width: 100%;
    margin-top: 10px;
}

/* Darker background on mouse-over */
.btn:hover {
    background-color: RoyalBlue;
}

.btn:disabled {
    background-color: #c8c8c8;
    cursor: default;
}

