/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
 .labelSliderRow {
	fill: red;
	height: 1.5rem;
	display: flex;
	flex-direction: row;
}

.floatLeft {
	float: left;
}