/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
.labelRangeColumn {
	float: left;
	width: 20%;
}

.columnRange {
	float: left;
	width: 72%;
}

.minInputStyle {
	width: 70px;
	float: right;
}

.maxInputStyle {
	
	width: 70px;
	float: right;
}

.dash {
	float: right;
	margin-left:10px;
	margin-right:10px;
}

.title {
	font-size: 20px;
}

.disabledMask {
	pointer-events: none;
	background-color:rgba(239, 239, 239, 0.3);
	color: rgb(84, 84, 84);
	border-color: rgba(118, 118, 118, 0.3);
}

.labelSliderRow {
	fill: red;
	height: 1.5rem;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-around;
}

.sliderStyle {
	width: 80%;
	float: center;
}

.sliderMinBound {
	float: left;
}

.sliderMaxBound {
	float: right;
}

.input[type='text']:disabled {
	background: #c8c8c8;
}
