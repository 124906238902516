/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
.curveButton {
    background: #0069eb;
    border-radius: 30px;
    border: none;
    color: #fff;
    font-size: 16px;
    padding: 15px 25px;
    cursor: pointer;
    margin-left: 10%;
    width: 80%;
}

.corridorSelector {
    float: right;
    width: 240px;
}

.curveButton:disabled {
    background: gray;
    border-radius: 30px;
    border: none;
    color: #fff;
    font-size: 16px;
    padding: 15px 25px;
    cursor: auto;
    margin-left: 10%;
    width: 80%;
}

.smallLeftMargin {
    margin-left: 15px;
}

.shortLabelRow {
    margin-top: 5px;
    height: 1.5rem;
    /* height: 1.5rem; */
    /* display: inline; */
}

.smallValueInputStyle {
    float: right;
    width: 80px;
}

.checkBoxRow {
    height: 2.5rem;
}

.spinner {
    height: 2rem;
    display: inline-block;
}

.materialsSpaceAround {
    width: 33%;
    /* align-items: flex-start; */
    /* justify-content: space-around; */
}

.neoccInputBox {
    width: 150px;
    margin: 0.5rem 0 1rem 25px;
}

.neoccText {
    margin-top: 0.6rem;
}

.neoccDropdownList {
    height: 400;
}

.btn {
    background-color: DodgerBlue;
    border: none;
    color: white;
    padding: 7px 20px;
    cursor: pointer;
    font-size: 18px;
    width: 100%;
    margin-top: 10px;
}

/* Darker background on mouse-over */
.btn:hover {
    background-color: RoyalBlue;
}

.btn:disabled {
    background-color: #c8c8c8;
    cursor: default;
}
