/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
.labelSliderRow {
    fill: red;
    height: 1.5rem;
    display: relative;
    flex-direction: row;
    align-items: flex-start;
    vertical-align: auto;
}
