/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
*/

.Log {
    margin: 2px;
    background-color: #282c34;
    height: 16vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: calc(15px);
    overflow-y: auto;
    resize: both;
}

.Log::-webkit-scrollbar {
    display: none;
}

.whiteText {
    color: white;
}

.logTitle {
    color: white;
    align-items: center;
    font-size: calc(20px);
    font-weight: bold;
    margin: auto;
}

.logClearConsole {
    position: absolute;
    right: 0px;

    background-color: DodgerBlue;
    border: none;
    color: white;
    width: 100px;
    font-size: 15px;
}

.logClearConsole:hover {
    background-color: RoyalBlue;
}
