/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
 .auth-background {
    height: 100vh;
    width: 100vw;
    padding-top: -30;
    background-image: url('../../assets/SpaceDebrisLandingPage.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    display: flex;
}

.auth {
    margin: auto;
    width: 95%;
    max-width: 25rem;
    border-radius: 6px;
    background-color: #060b26;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    text-align: center;
    color: white;
}

.auth h1 {
    text-align: center;
    color: white;
}

.control {
    margin-bottom: 0.5rem;
}

.control label {
    display: block;
    color: white;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.control input {
    font: inherit;
    background-color: #f1e1fc;
    color: #38015c;
    border-radius: 4px;
    border: 1px solid white;
    width: 100%;
    text-align: left;
    padding: 0.25rem;
}

.actions {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.actions button {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: #9f5ccc;
    border: 1px solid #9f5ccc;
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
}

.actions button:hover {
    background-color: #873abb;
    border-color: #873abb;
}

.actions .toggle {
    margin-top: 1rem;
    background-color: transparent;
    color: #9f5ccc;
    border: none;
    padding: 0.15rem 1.5rem;
}

.actions .toggle:hover {
    background-color: transparent;
    color: #ae82cc;
}
.error-text {
    color: #b40e0e;
}
