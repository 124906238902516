/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */

.btn {
    background-color: DodgerBlue;
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 18px;
    width: 100%;
}

/* Darker background on mouse-over */
.btn:hover {
    background-color: RoyalBlue;
}

.btn:disabled {
    background-color: #c8c8c8;
    cursor: default;
}

.kml-button {
    margin-top: 5px;
}
