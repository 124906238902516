/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.online-Users {
    float: right;
    width: 15%;
    height: 100vh;
    border-left-width: 1px;
    border-left-style: solid;
}

html,
body {
    max-width: 100%;
    overflow-x: hidden;
}

.grayedOut {
    color: #c8c8c8;
}

.disabledMask {
    pointer-events: none;
    background-color: rgba(239, 239, 239, 0.3);
    color: rgb(84, 84, 84);
    border-color: rgba(118, 118, 118, 0.3);
}

.strong {
    font-weight: bold;
}

.title {
    font-size: 20px;
}

.labelRow {
    height: 2rem;
}

.valueInputStyle {
    float: right;
    width: 82px;
}

.labelStyle {
    float: left;
}

.blueCenteredButton {
    background: #0069eb;
    border-radius: 30px;
    border: none;
    color: #fff;
    font-size: 16px;
    padding: 15px;
    cursor: pointer;
    margin-left: 24%;
    width: 50%;
}

.blueCenteredButton:disabled {
    background: grey;
    cursor: default;
}

.whiteText {
    color: white;
}

.logTitle {
    color: white;
    align-items: center;
    font-size: calc(20px);
    font-weight: bold;
    margin: auto;
}

.sc5::-webkit-scrollbar {
    width: 10px;
    height: 15px;
}
.sc5::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
}
.sc5::-webkit-scrollbar-thumb {
    background-image: linear-gradient(45deg, DodgerBlue, #060b26);
    /* border-radius: 10px; */
    -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0 3px 13px 1px;
}

body::-webkit-scrollbar {
    width: 10px;
    height: 15px;
}
body::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
}
body::-webkit-scrollbar-thumb {
    background-image: linear-gradient(45deg, DodgerBlue, #060b26);
    /* border-radius: 10px; */
    -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0 3px 13px 1px;
}

.contextMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: auto;
    width: fit-content;
    /* background-color: DodgerBlue; */
    background-color: white;
    opacity: 0.9;

    border-radius: 10px;
}

.optionsList {
    /* padding: 12px; */
    min-width: 107px;
    /* border-radius: 10px; */
}

.optionListItem {
    padding: 6px;
    border-radius: 10px;
    list-style-type: none;
}

.optionListItem:hover {
    cursor: pointer;
}

.customContextmenuArea1ListItem {
    /* list-style-type: disclosure-closed; */
}

.customContextmenuArea1ListItem:hover {
    background-color: dodgerblue;
    border-width: 10px;
}

textarea:focus,
input:focus {
    outline: none;
}

.displayInline {
    display: inline;
}

.displayBlock {
    display: block;
}