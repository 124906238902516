/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
 .centered-elements {
	/* display: flex; */
	/* height: 4rem; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	padding: 5px;
    height: 100px;
}

.redText {
	background: #fff;
	color: red;
	font-family: 'Helvetica', 'Arial', sans-serif;
	font-size: 2em;
	font-weight: bold;
	text-align: center;
	border-radius: 5px;
}

.greenText {
	background: #fff;
	color: green;
	font-family: 'Helvetica', 'Arial', sans-serif;
	font-size: 2em;
	font-weight: bold;
	text-align: center;
	border-radius: 5px;
}
