/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
 .lds-ripple {
	display: inline-block;
	position: relative;
	/* position:inherit; */
	width: 30px;
	height: 30px;
}

.lds-ripple div {
	position: absolute;
	border: 4px solid yellow;
	opacity: 1;
	border-radius: 50%;
	animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
	animation-delay: -0.5s;
}
@keyframes lds-ripple {
	0% {
		top: 1rem;
		left: 1rem;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 2rem;
		height: 2rem;
		opacity: 0;
	}
}

.readyIcon {
	margin-left: 10px;
	width: 20px;
	height: 20px;
	color: green;
}

.errorIcon {
	margin-left: 10px;
	width: 20px;
	height: 20px;
	color: red;
}

.idleIcon {
	margin-left: 10px;
	width: 20px;
	height: 20px;
	color: orange;
}
