/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
.labelRangeColumn {
    float: left;
    width: 30%;
}

.columnRange {
    float: left;
    width: 70%;
}

.minInputStyle {
    width: 70px;
    float: right;
}

.maxInputStyle {
    width: 70px;
    float: right;
}

.dash {
    float: right;
    margin-left: 10px;
    margin-right: 10px;
}

.floatCenter {
    height: 28px;
    width: 40px;
    margin-left: 110px;
    padding-top: 2px;
    padding-bottom: 2px;
    border: 1px solid #0069eb;
    border-radius: 30px;
    background: #0069eb;
    color: white;
    cursor: pointer;
}

.title {
    font-size: 20px;
}

.inactiveLink {
    pointer-events: none;
    cursor: default;
}

.noDecorations {
    text-decoration: none;
}

.disabledDiv {
    opacity: 0.7;
    /* border:2px solid DodgerBlue;;
    border-radius: 2%;
    margin:5px; */
}
