/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
.labelSliderRow {
    fill: red;
    height: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
}

.blueCenteredButton {
    background: #0069eb;
    border-radius: 30px;
    border: none;
    color: #fff;
    font-size: 16px;
    padding: 15px;
    cursor: pointer;
    margin-left: 24%;
    width: 50%;
}

.redCenteredButton {
    background: red;
    border-radius: 30px;
    border: none;
    color: #fff;
    font-size: 16px;
    padding: 15px;
    cursor: pointer;
    margin-left: 24%;
    width: 53%;
}
.redCenteredButton :focus-visible {
    outline: 0;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
}

.blueCenteredButton:disabled {
    background: grey;
    cursor: default;
}

.smallMarginLeft {
    margin-left: 10px;
}

.shortLine {
    width: 30%;
    align-items: center;
}

.neoccLabelRow {
    height: 2rem;
}

.verticalAlignCenter {
    vertical-align: middle;
}

.neoccInputBox {
    width: 150px;
    margin: 0.5rem 0 1rem 25px;
}

.neoccText {
    margin-top: 0.6rem;
}

.neoccDropdownList {
    height: 400;
}

.floatRight {
    float: right;
}

.customRockSelector {
    width: 190px;
}
