/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
.column {
    overflow: auto;
}

.userCommandPanel {
    width: 300px;
    height: 90vh;
    float: left;
    margin-left: 8px;
}

.cesiumContainer {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.parameters-column {
    position: relative;
    top: -20px;
    height: 90vh;
    overflow-y: auto;
    padding-right: 3px;
}

.fullImg {
    max-width: 100%;
    max-height: 100%;
}

.basicColorMap {
    position: absolute;
    width: 70%;
    top: 5px;
    left: 5px;
    background-color: rgba(255, 255, 255, 0.7);
}

.hazardColorMap {
    position: absolute;
    height: 80px;
    top: 5px;
    left: 5px;
    background-color: rgba(255, 255, 255, 0.7);
}

.energyReleaseMap {
    position: absolute;
    height: 50vh;
    bottom: 5px;
    left: 5px;
    background-color: rgba(255, 255, 255, 0.5);
}

.observerDiamond {
    position: absolute;
    height: 50vh;
    right: 5px;
    bottom: 5px;
    background-color: rgba(255, 255, 255, 0.5);
}

.cesium-lateral-toolbar {
    display: inline;
    position: absolute;
    width: 40px;
    top: 42px;
    right: 3px;
}

.cesium-lateral-toolbar-button {
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    border-radius: 14%;
    padding: 0;
    vertical-align: middle;
    z-index: 0;
}

.tabs {
    display: inline-block;
    position: relative;
    top: -27px;
    width: 312px;
    z-index: 1001;
    margin-left: -8px;
}

.tab {
    display: inline-block;
    position: relative;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    /* background-color: #fff; */
    background-image: linear-gradient(to top, #fff, dodgerblue);
    border-bottom: 1px solid #ccc;
    width: 33%;
    height: 25px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    z-index: 10001;
}

.tabActive {
    background-color: #ccc;
}

.line {
    background: dodgerblue;
    height: 3px;
    width: 100px;
    transition: 0.25s ease;

    position: relative;
    top: -27px;
    z-index: 1001;
    margin-left: -8px;
}

.displayNone {
    display: none;
}
