/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */

.navbar {
    background-color: #060b26;
    height: 60px;
    justify-content: flex-start;
    align-items: center;
    z-index: 1000;
}

.span-title {
    margin-left: 16px;
}

.users-icon {
    right: 0;
    font-size: 2rem;
    margin-right: 1rem;
    margin-top: 1rem;
    background: none;
    float: right;
}

.nav-users {
    background-color: #fff;
    width: 400px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
    overflow: auto;
    overflow-y: scroll;
    bottom: 0;
    z-index: 1000;
}

.nav-users::-webkit-scrollbar {
    display: none;
}

.nav-users.active {
    right: 0;
    transition: 350ms;
    z-index: 1000;
}

.nav-users-items {
    width: 400px;
    padding-left: 20px;
}

.nav-users-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
}

.users-toggle {
    background-color: #fff;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
